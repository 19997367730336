import {
  faArrowTurnRight,
  faCommentDots,
  faImage,
  faNewspaper,
  faPaperPlane,
  faThumbsUp,
  faToolbox,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Posts_Data = [
  {
    name: "Prateek Shukla",
    about_avatar:
      "Hiring for leadership positions in product & engineering at Masai",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/60.jpg",
    about_post:
      "🚨 Announcement 🚨  National Skill Development Corporation 🤝 Masai School to skill 1.5 lacs students of 🇮🇳 What it entails for our students 👇 - All the students who graduate from Masai will be certified by the NSDC at the time of graduation 😍",
    time: "3w",
    post_picture:
      "https://gumlet.assettype.com/freepressjournal/2022-03/8c68c141-fca0-4ebc-beb2-5592d2987ba3/Masai_partners_with_NSDC__image_2.jpg?format=webp&dpr=1.0&q=70&w=768",
    likes: "Saurabh Yadav and 1,090 others",
    comments: "45 comments",
    icons: [
      {
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        text: "Like",
      },
      {
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        text: "Comment",
      },
      {
        icon: <FontAwesomeIcon icon={faArrowTurnRight} />,
        text: "Share",
      },
      // {
      //   icon: <FontAwesomeIcon icon={faPaperPlane} />,
      //   text: "Send",
      // },
    ],
  },
  {
    name: "Yogesh Bhat",
    about_avatar: "Co-founder & SVP @Masai School |Transformation in Educat.",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/61.jpg",
    about_post:
      "# 18 / 101 Power Skills - Active Patience Power skills help individuals to # achieve their potential # achieve their aspirations Active patience demands actions & Good intentions while waiting for results to be achieved.",
    time: "24h",
    post_picture:
      "https://media-exp1.licdn.com/dms/image/C5622AQE-pWsM1rZhWg/feedshare-shrink_800/0/1649867369564?e=2147483647&v=beta&t=YzNkBS3nB_MqADk92m8iSRAW74xQsYKY4xNija-P7f8",
    likes: "Devesh Verma and 99 others",
    comments: "20 comments",
    icons: [
      {
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        text: "Like",
      },
      {
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        text: "Comment",
      },
      {
        icon: <FontAwesomeIcon icon={faArrowTurnRight} />,
        text: "Share",
      },
      // {
      //   icon: <FontAwesomeIcon icon={faPaperPlane} />,
      //   text: "Send",
      // },
    ],
  },
  {
    name: "Deepak Sharma",
    about_avatar: "Full Stack JavaScript Developer",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/62.jpg",
    about_post: "First reward from my corporate life #credilio",
    time: "6mo",
    post_picture:
      "https://media-exp1.licdn.com/dms/image/C4E22AQEWaflE7xAVnA/feedshare-shrink_800/0/1637339655972?e=2147483647&v=beta&t=IhAqB8T0D1MfwzGBA_6rZx9orwn3rV77E4-jzrKN2Ms",
    likes: "Abhishek prajapati and 136 others",
    comments: "5 comments",
    icons: [
      {
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        text: "Like",
      },
      {
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        text: "Comment",
      },
      {
        icon: <FontAwesomeIcon icon={faArrowTurnRight} />,
        text: "Share",
      },
      // {
      //   icon: <FontAwesomeIcon icon={faPaperPlane} />,
      //   text: "Send",
      // },
    ],
  },
  {
    name: "Bhabana Samantara",
    about_avatar:
      "Frontend web developer at The UniQue Culture| A Multinational Software Development",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/63.jpg",
    about_post:
      "Here is the Certificate of Web development from Hindi Tech Tutorials... Thank you Rajdeep Dhakad sir for being a good mentor and for guiding me on the right path...I will always be grateful to you for your support 🙏🙏😊",
    time: "1yr",
    post_picture:
      "https://media-exp1.licdn.com/dms/image/C5622AQE3LrydUAfdXg/feedshare-shrink_800/0/1620451203123?e=2147483647&v=beta&t=kAjkntY0yh_EvlXNX1Gd1L--Fybm_PByEE5SthKO73s",
    likes: "You and 26 others",
    comments: "20 comments",
    icons: [
      {
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        text: "Like",
      },
      {
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        text: "Comment",
      },
      {
        icon: <FontAwesomeIcon icon={faArrowTurnRight} />,
        text: "Share",
      },
      // {
      //   icon: <FontAwesomeIcon icon={faPaperPlane} />,
      //   text: "Send",
      // },
    ],
  },
  {
    name: "Gurdeep Singh",
    about_avatar: "Associate SDE | Actyv.ai",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/64.jpg",
    about_post:
      "I'm delighted to share with you that I have joined Actyv.ai® as an Associate SDE.Thanks, Masai School for making my dream True.Special thanks to co-founders Nrupul Dev, Yogesh Bhat, and Prateek Shukla for creating such an amazing platform to create and grow your skills.",
    time: "2w",
    post_picture:
      "https://media-exp1.licdn.com/dms/image/C5622AQFw2DHgw6Bv5Q/feedshare-shrink_800/0/1649414144325?e=2147483647&v=beta&t=SdidsBK2MgrI1aLzQK1PuvlmBBemUd4sD1Fkv9UE1G0",
    likes: "Sudhanshu Kumar and 20 others",
    comments: "12 comments",
    icons: [
      {
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        text: "Like",
      },
      {
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        text: "Comment",
      },
      {
        icon: <FontAwesomeIcon icon={faArrowTurnRight} />,
        text: "Share",
      },
      // {
      //   icon: <FontAwesomeIcon icon={faPaperPlane} />,
      //   text: "Send",
      // },
    ],
  },
  {
    name: "Manthan Gaikwad",
    about_avatar: "Associate Software Engineer @ Indus Net Technologies",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/65.jpg",
    about_post:
      "#15 / 101 Power Skills -  Marathon Mindset  Power skills help individuals to # achieve their potential # achieve their aspirations Life is a marathon, not a sprint; pace yourself accordingly -Amby B A marathon mindset means that",
    time: "2w",
    post_picture:
      "https://media.licdn.com/media/AAYQAQQSAAgAAQAAAAAAABqdcB5EZ18gQPCF4HzBOFBBhw.gif",
    likes: "Kapil Mogre and 165 others",
    comments: "20 comments",
    icons: [
      {
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        text: "Like",
      },
      {
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        text: "Comment",
      },
      {
        icon: <FontAwesomeIcon icon={faArrowTurnRight} />,
        text: "Share",
      },
      // {
      //   icon: <FontAwesomeIcon icon={faPaperPlane} />,
      //   text: "Send",
      // },
    ],
  },
  {
    name: "Akshay Saini",
    about_avatar: "Engineer at Uber | Teacher | YouTuber",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/66.jpg",
    about_post:
      "Wohooo, what a meet-up it was in Hyderabad!! 🤩🤩 Thank you for taking out time and joining,it was just amazing meeting you in person. 😇",
    time: "2w",
    post_picture:
      "https://media-exp1.licdn.com/dms/image/C4D22AQHeoiyw9PReQw/feedshare-shrink_2048_1536/0/1650335402761?e=2147483647&v=beta&t=VBjzMsubTfOYEdamVPNg6DVy8rHCCRI_w331dDUE808",
    likes: "You and 6,220 others",
    comments: "23 comments",
    icons: [
      {
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        text: "Like",
      },
      {
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        text: "Comment",
      },
      {
        icon: <FontAwesomeIcon icon={faArrowTurnRight} />,
        text: "Share",
      },
      // {
      //   icon: <FontAwesomeIcon icon={faPaperPlane} />,
      //   text: "Send",
      // },
    ],
  },
  {
    name: "Abhishek Maurya",
    about_avatar:
      "Instructional Associate at Masai School | Full-Stack Dev | Trader | Investor",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/66.jpg",
    about_post:
      "Hello fam, I am delighted to share that I have joined Masai School as an Instructional Associate. I always loved the environment, culture and team spirit her",
    time: "2mo",
    post_picture:
      "https://media.licdn.com/media/AAYQAQQSAAgAAQAAAAAAABwEEXEeLoHISM2cU9LdzliLaQ.gif",
    likes: "Rohan Verma and 130 others",
    comments: "30 comments",
    icons: [
      {
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        text: "Like",
      },
      {
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        text: "Comment",
      },
      {
        icon: <FontAwesomeIcon icon={faArrowTurnRight} />,
        text: "Share",
      },
      // {
      //   icon: <FontAwesomeIcon icon={faPaperPlane} />,
      //   text: "Send",
      // },
    ],
  },
  {
    name: "Sheelu Kumar",
    about_avatar: "Full Stack Dev | Instructional associate @masaischool",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/67.jpg",
    about_post:
      "Hello everyone, I feel really blessed to share that I have now Joined Masai School as an Instructional Associate (IA).It have been a wonderful journey in masai and thanks to all the support and training given by Nrupul Dev Yogesh Bhat Prateek Shukla Chandra Sekhar Swanand Kadam Dhaval Chheda Prabhanjan Padhye  Neeraj Malwal and all Masai School operation team.",
    time: "1mo",
    post_picture:
      "https://media.licdn.com/media/AAYQAQQSAAgAAQAAAAAAABqdcB5EZ18gQPCF4HzBOFBBhw.gif",
    likes: "You and 120 others",
    comments: "25 comments",
    icons: [
      {
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        text: "Like",
      },
      {
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        text: "Comment",
      },
      {
        icon: <FontAwesomeIcon icon={faArrowTurnRight} />,
        text: "Share",
      },
      // {
      //   icon: <FontAwesomeIcon icon={faPaperPlane} />,
      //   text: "Send",
      // },
    ],
  },
  {
    name: "Prateek Shukla",
    about_avatar:
      "Hiring for leadership positions in product & engineering at Masai",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/68.jpg",
    about_post:
      "🚨 Announcement 🚨  National Skill Development Corporation 🤝 Masai School to skill 1.5 lacs students of 🇮🇳 What it entails for our students 👇 - All the students who graduate from Masai will be certified by the NSDC at the time of graduation 😍",
    time: "3w",
    post_picture:
      "https://gumlet.assettype.com/freepressjournal/2022-03/8c68c141-fca0-4ebc-beb2-5592d2987ba3/Masai_partners_with_NSDC__image_2.jpg?format=webp&dpr=1.0&q=70&w=768",
    likes: "Saurabh Yadav and 1,090 others",
    comments: "45 comments",
    icons: [
      {
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        text: "Like",
      },
      {
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        text: "Comment",
      },
      {
        icon: <FontAwesomeIcon icon={faArrowTurnRight} />,
        text: "Share",
      },
      // {
      //   icon: <FontAwesomeIcon icon={faPaperPlane} />,
      //   text: "Send",
      // },
    ],
  },
  {
    name: "Yogesh Bhat",
    about_avatar: "Co-founder & SVP @Masai School |Transformation in Educat.",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/69.jpg",
    about_post:
      "# 18 / 101 Power Skills - Active Patience Power skills help individuals to # achieve their potential # achieve their aspirations Active patience demands actions & Good intentions while waiting for results to be achieved.",
    time: "24h",
    post_picture:
      "https://media-exp1.licdn.com/dms/image/C5622AQE-pWsM1rZhWg/feedshare-shrink_800/0/1649867369564?e=2147483647&v=beta&t=YzNkBS3nB_MqADk92m8iSRAW74xQsYKY4xNija-P7f8",
    likes: "Devesh Verma and 99 others",
    comments: "20 comments",
    icons: [
      {
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        text: "Like",
      },
      {
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        text: "Comment",
      },
      {
        icon: <FontAwesomeIcon icon={faArrowTurnRight} />,
        text: "Share",
      },
      // {
      //   icon: <FontAwesomeIcon icon={faPaperPlane} />,
      //   text: "Send",
      // },
    ],
  },
  {
    name: "Deepak Sharma",
    about_avatar: "Full Stack JavaScript Developer",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/70.jpg",
    about_post: "First reward from my corporate life #credilio",
    time: "6mo",
    post_picture:
      "https://media-exp1.licdn.com/dms/image/C4E22AQEWaflE7xAVnA/feedshare-shrink_800/0/1637339655972?e=2147483647&v=beta&t=IhAqB8T0D1MfwzGBA_6rZx9orwn3rV77E4-jzrKN2Ms",
    likes: "Abhishek prajapati and 136 others",
    comments: "5 comments",
    icons: [
      {
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        text: "Like",
      },
      {
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        text: "Comment",
      },
      {
        icon: <FontAwesomeIcon icon={faArrowTurnRight} />,
        text: "Share",
      },
      // {
      //   icon: <FontAwesomeIcon icon={faPaperPlane} />,
      //   text: "Send",
      // },
    ],
  },
  {
    name: "Bhabana Samantara",
    about_avatar:
      "Frontend web developer at The UniQue Culture| A Multinational Software Development",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/71.jpg",
    about_post:
      "Here is the Certificate of Web development from Hindi Tech Tutorials... Thank you Rajdeep Dhakad sir for being a good mentor and for guiding me on the right path...I will always be grateful to you for your support 🙏🙏😊",
    time: "1yr",
    post_picture:
      "https://media-exp1.licdn.com/dms/image/C5622AQE3LrydUAfdXg/feedshare-shrink_800/0/1620451203123?e=2147483647&v=beta&t=kAjkntY0yh_EvlXNX1Gd1L--Fybm_PByEE5SthKO73s",
    likes: "You and 26 others",
    comments: "20 comments",
    icons: [
      {
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        text: "Like",
      },
      {
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        text: "Comment",
      },
      {
        icon: <FontAwesomeIcon icon={faArrowTurnRight} />,
        text: "Share",
      },
      // {
      //   icon: <FontAwesomeIcon icon={faPaperPlane} />,
      //   text: "Send",
      // },
    ],
  },
  {
    name: "Gurdeep Singh",
    about_avatar: "Associate SDE | Actyv.ai",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/73.jpg",
    about_post:
      "I'm delighted to share with you that I have joined Actyv.ai® as an Associate SDE.Thanks, Masai School for making my dream True.Special thanks to co-founders Nrupul Dev, Yogesh Bhat, and Prateek Shukla for creating such an amazing platform to create and grow your skills.",
    time: "2w",
    post_picture:
      "https://media.licdn.com/media/AAYQAQQSAAgAAQAAAAAAABwEEXEeLoHISM2cU9LdzliLaQ.gif",
    likes: "Sudhanshu Kumar and 20 others",
    comments: "12 comments",
    icons: [
      {
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        text: "Like",
      },
      {
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        text: "Comment",
      },
      {
        icon: <FontAwesomeIcon icon={faArrowTurnRight} />,
        text: "Share",
      },
      // {
      //   icon: <FontAwesomeIcon icon={faPaperPlane} />,
      //   text: "Send",
      // },
    ],
  },
  {
    name: "Manthan Gaikwad",
    about_avatar: "Associate Software Engineer @ Indus Net Technologies",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/47.jpg",
    about_post:
      "#15 / 101 Power Skills -  Marathon Mindset  Power skills help individuals to # achieve their potential # achieve their aspirations Life is a marathon, not a sprint; pace yourself accordingly -Amby B A marathon mindset means that",
    time: "2w",
    post_picture:
      "https://media.licdn.com/media/AAYQAQQSAAgAAQAAAAAAABqdcB5EZ18gQPCF4HzBOFBBhw.gif",
    likes: "Kapil Mogre and 165 others",
    comments: "20 comments",
    icons: [
      {
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        text: "Like",
      },
      {
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        text: "Comment",
      },
      {
        icon: <FontAwesomeIcon icon={faArrowTurnRight} />,
        text: "Share",
      },
      // {
      //   icon: <FontAwesomeIcon icon={faPaperPlane} />,
      //   text: "Send",
      // },
    ],
  },
  {
    name: "Akshay Saini",
    about_avatar: "Engineer at Uber | Teacher | YouTuber",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/45.jpg",
    about_post:
      "Do you wanna fall in love with JavaScript? Watch this FREE series on YouTube. ❤️",
    time: "2w",
    post_picture:
      "https://media.licdn.com/media/AAYQAQQSAAgAAQAAAAAAABwEEXEeLoHISM2cU9LdzliLaQ.gif",
    likes: "You and 6,220 others",
    comments: "23 comments",
    icons: [
      {
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        text: "Like",
      },
      {
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        text: "Comment",
      },
      {
        icon: <FontAwesomeIcon icon={faArrowTurnRight} />,
        text: "Share",
      },
      // {
      //   icon: <FontAwesomeIcon icon={faPaperPlane} />,
      //   text: "Send",
      // },
    ],
  },
  {
    name: "Abhishek Maurya",
    about_avatar:
      "Instructional Associate at Masai School | Full-Stack Dev | Trader | Investor",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/46.jpg",
    about_post:
      "Hello fam, I am delighted to share that I have joined Masai School as an Instructional Associate. I always loved the environment, culture and team spirit her",
    time: "2mo",
    post_picture:
      "https://media.licdn.com/media/AAYQAQQSAAgAAQAAAAAAABwEEXEeLoHISM2cU9LdzliLaQ.gif",
    likes: "Rohan Verma and 130 others",
    comments: "30 comments",
    icons: [
      {
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        text: "Like",
      },
      {
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        text: "Comment",
      },
      {
        icon: <FontAwesomeIcon icon={faArrowTurnRight} />,
        text: "Share",
      },
      // {
      //   icon: <FontAwesomeIcon icon={faPaperPlane} />,
      //   text: "Send",
      // },
    ],
  },
  {
    name: "Sheelu Kumar",
    about_avatar: "Full Stack Dev | Instructional associate @masaischool",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/47.jpg",
    about_post:
      "Hello everyone, I feel really blessed to share that I have now Joined Masai School as an Instructional Associate (IA).It have been a wonderful journey in masai and thanks to all the support and training given by Nrupul Dev Yogesh Bhat Prateek Shukla Chandra Sekhar Swanand Kadam Dhaval Chheda Prabhanjan Padhye  Neeraj Malwal and all Masai School operation team.",
    time: "1mo",
    post_picture:
      "https://media.licdn.com/media/AAYQAQQSAAgAAQAAAAAAABqdcB5EZ18gQPCF4HzBOFBBhw.gif",
    likes: "You and 120 others",
    comments: "25 comments",
    icons: [
      {
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        text: "Like",
      },
      {
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        text: "Comment",
      },
      {
        icon: <FontAwesomeIcon icon={faArrowTurnRight} />,
        text: "Share",
      },
      // {
      //   icon: <FontAwesomeIcon icon={faPaperPlane} />,
      //   text: "Send",
      // },
    ],
  },
];

export const NAVBAR_ICONS = [
  {
    icon: <FontAwesomeIcon className="fa-solid photo" icon={faImage} />,
    text: "Photo",
  },
  {
    icon: <FontAwesomeIcon className="fa-solid video" icon={faVideo} />,
    text: "Video",
  },
  // {
  //   icon: <FontAwesomeIcon className="fa-solid job" icon={faToolbox} />,
  //   text: "Job",
  // },
  // {
  //   icon: <FontAwesomeIcon className="fa-solid article" icon={faNewspaper} />,
  //   text: "Write aritcle",
  // },
];

export const WHO_TO_CONNECT = [
  {
    name: "Devesh Verma ",
    username: "deveshverma",
    avatar:"https://xsgames.co/randomusers/assets/avatars/male/30.jpg",
  },
  {
    name: "Deepak Sharma",
    username: "dasjideepak",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/31.jpg",
  },

  {
    name: "Saurabh Yadav",
    username: "saurabhyadav",
    avatar:"https://xsgames.co/randomusers/assets/avatars/male/32.jpg",
  },
  {
    name: "Sudhanshu kumar",
    username: "sudhanshukumar",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/33.jpg",
  },
  {
    name: "Abhishek Prajapati",
    username: "abhishekprajapati",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/34.jpg",
  },
  {
    name: "Shadik Husain",
    username: "shadikhusain",
    avatar:
      "https://xsgames.co/randomusers/assets/avatars/male/35.jpg",
  },
  // {
  //   name: "Abhishek Shukla",
  //   username: "abhishekshukla",
  //   avatar:
  //     "https://media-exp1.licdn.com/dms/image/D4E35AQF9Ta3ja8CIpA/profile-framedphoto-shrink_800_800/0/1648032016990?e=2147483647&v=beta&t=8HZxGFrQESFtdHpyV_rOSOxbqFHAxOue-QG6_YmiOLs",
  // },
];

export const LATEST_NEWS = [
  {
    heading: "Tech Giants Collaborate to Combat Climate Change",
    details: "Major technology companies, including Apple, Google, and Microsoft, have announced a joint initiative to reduce carbon emissions by 50% over the next decade. The collaboration focuses on renewable energy, sustainable manufacturing processes, and innovative technologies to minimize environmental impact.",
  },
  {
    heading: "Breakthrough in Cancer Research Offers New Hope",
    details: "Scientists at the University of Cambridge have developed a groundbreaking treatment that targets cancer cells more precisely, reducing side effects and increasing survival rates. Clinical trials have shown promising results, especially in patients with advanced stages of the disease.",
  },
  // {
  //   heading: "Record-Breaking Heatwave Sweeps Across Europe",
  //   details: "Europe is experiencing an unprecedented heatwave, with temperatures soaring above 40°C (104°F) in many countries. Governments have issued heat warnings, and experts are urging people to stay hydrated and avoid outdoor activities during peak hours.",
  // },
  // {
  //   heading: "Innovative Education Model Gains Popularity in Schools",
  //   details: "A new education model that emphasizes personalized learning and hands-on experience is being adopted by schools across the United States. This approach aims to improve student engagement and performance by catering to individual learning styles and interests.",
  // },
  // {
  //   heading: "SpaceX Successfully Launches First Commercial Spaceflight",
  //   details: "SpaceX has made history by launching the first commercial spaceflight with a crew of private astronauts. The mission, which will orbit the Earth for three days, marks a significant milestone in making space travel accessible to non-professionals.",
  // },
  // {
  //   heading: "Global Efforts to Protect Endangered Species Intensify",
  //   details: "International conservation organizations have joined forces to launch a global campaign aimed at protecting endangered species. The initiative focuses on habitat preservation, anti-poaching measures, and raising public awareness about the importance of biodiversity.",
  // },
  // {
  //   heading: "Breakthrough in Renewable Energy Storage Technology",
  //   details: "Researchers at MIT have developed a new energy storage system that significantly improves the efficiency and capacity of renewable energy sources like solar and wind. This innovation could accelerate the transition to sustainable energy by addressing the issue of intermittency.",
  // },
  // {
  //   heading: "Major Advancements in Artificial Intelligence Announced",
  //   details: "At the annual AI Summit, leading tech companies unveiled major advancements in artificial intelligence, including improved natural language processing, autonomous systems, and ethical AI frameworks. These developments promise to revolutionize various industries, from healthcare to transportation.",
  // },
  // {
  //   heading: "Groundbreaking Study on Mental Health in the Workplace",
  //   details: "A recent study by Harvard University highlights the importance of mental health support in the workplace. The research suggests that companies that prioritize employee well-being see increased productivity and job satisfaction. Recommendations include flexible work hours, mental health days, and on-site counseling services.",
  // },
  // {
  //   heading: "Historic Archaeological Discovery in Egypt",
  //   details: "Archaeologists have uncovered a previously unknown pharaoh's tomb in Egypt's Valley of the Kings. The tomb, which dates back over 3,000 years, contains well-preserved artifacts and hieroglyphics that provide new insights into ancient Egyptian civilization.",
  // }
];
