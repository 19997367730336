import "../styles/Notification.css";
import BellImg from "../../bellimg.png";
export const Notification = () => {
  return (
    <>
      <div className="main">
      <div className="centered-container">
            <img className="notification-image" src={BellImg} alt="No new notifications yet" />

            <h1>No new notifications yet</h1>
            <h2>Please check back later</h2>
        </div>
        </div>
    </>
  );
};
