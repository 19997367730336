import "../styles/MyNetwork.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookmark,
  faSquare,
  faEarth,
  faHeart,
  faLightbulb,
  faThumbsUp,
  faAngleDown,
  faUserPlus,

} from "@fortawesome/free-solid-svg-icons";
import { NAVBAR_ICONS, Posts_Data, LATEST_NEWS } from "../../Data/data";
import Logo from "../../logo.png";

export const MyNetwork = () => {
  return (
    <>
    <div className="main">
      <div className="homeContainer">
        <div className="leftSide">
          <div className="profileDiv">
            <div className="backGroundDiv">
              {/* <img
                src={Logo}
                className="bgImg"
                alt=""
              /> */}
              <img
                src="https://xsgames.co/randomusers/assets/avatars/male/37.jpg"
                alt=""
                className="avatarImg"
              />
              <h4 className="avatarName">Prateek Shukla</h4>
              <p className="avatarAbout">
              Hiring for leadership positions in product & engineering at Masai
              </p>
              <div className="hr"></div>
              <div className="viewes">
                <h3>Who's viewed your profile </h3>
                <h4>400</h4>
              </div>
              <div className="viewes">
                <h3>Views of your video </h3>
                <h4>850</h4>
              </div>
              <div className="hr"></div>
              <div className="premium">
                <p>Access exclusive tools & insights</p>
                <h4>
                  <FontAwesomeIcon className="squire" icon={faSquare} /> Try
                  Premium for free
                </h4>
              </div>
              <div className="hr"></div>
              <div className="items">
                <h4>
                  <FontAwesomeIcon className="bookmark" icon={faBookmark} />
                  Saved Post
                </h4>
              </div>
            </div>
          </div>
          <div className="groups">
            <h4 className="h4Color">Groups</h4>
            <div className="events">
              <h4 className="h4Color">Events</h4>
              <h5>+</h5>
            </div>
            <h4 className="h4Color">Followed Hashtags</h4>
            <div className="hr"></div>
            <h4 className="discover">Discover more</h4>
          </div>
        </div>
        <div className="middleSide">
          
          {Posts_Data.map((el, i) => (
            <UserPosts
              key={i}
              avatar={el.avatar}
              name={el.name}
              about_avatar={el.about_avatar}
              about_post={el.about_post}
              post_picture={el.post_picture}
              time={el.time}
              likes={el.likes}
              icons={el.icons}
            />
          ))}
        </div>
        <div className="rightSide">
          <div className="rightContainer">
            <h3 className="connectHeading">Latest News</h3>
            {LATEST_NEWS.map((el, i) => (
              <LATESTNEWS
                key={i}
                heading={el.heading}
                details={el.details}
              />
            ))}
            <hr />
            <div className="showMorediv">
              <h3>
                Show more <FontAwesomeIcon icon={faAngleDown} />
              </h3>
            </div>
          </div>
          <footer className="footer">
            <div>
              <span>About</span>
              <span>Accessibility</span>
              <span>Help Center</span>
            </div>
            <div>
              <span>
                Privacy & Terms{" "}
                {<FontAwesomeIcon className="downArrow" icon={faAngleDown} />}
              </span>
              <span>Ad Choices</span>
            </div>
            <div>
              <span>Advertising </span>
              <span>
                Business Services{" "}
                {<FontAwesomeIcon className="downArrow" icon={faAngleDown} />}
              </span>
            </div>
            <div>
              <span>Get the app</span>
              <span>More</span>
            </div>
            <div className="corporation">
              <span>Wilayah corporation © 2022</span>
              <br />
              <span className="linked">
                {/* Linked <span className="in">in</span> */}
                <img
                src={Logo}
                className=""
                alt=""
                style={{ height:'25px' }}
              />
              </span>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </>
  );
};
const NavbarIcons = (props) => {
  return (
    <div className="btnicon">
      <span>{props.icon}</span>
      <span className="icontext">{props.text}</span>
    </div>
  );
};

function UserPosts(props) {
  return (
    <div className="postsMainDiv">
      <div className="useDetails">
        <div className="avatarDetails">
          <img className="userAvatar" src={props.avatar} alt="" />
          <div>
            <h4>{props.name}</h4>
            <p>{props.about_avatar}</p>
          </div>
        </div>
      </div>
      <div className="hr"></div>
      <div className="viewerReactionMain">
          <div className="viewerReaction">
            <h5>View Details</h5>
            <p></p>
          </div>
          <div className="viewerReaction">
            <h5>Messaging</h5>
          </div>
      </div>
    </div>
  );
}

const LATESTNEWS = (props) => {
  return (
    <div className="whoToConnect_container">
      <div className="account">
        {/* <div className="img_src">
          <img src={props.avatar} alt="userAvatar" />
        </div> */}
        <div className="connect_user_name">
          <h3>{props.heading}</h3>
          <h4>{props.details}</h4>
        </div>
      </div>

      {/* <button className="connect_btn">Connect&nbsp;&nbsp;{<FontAwesomeIcon className="userplus" icon={faUserPlus} />}</button> */}
    </div>
  );
};
